<template>
  <v-container style="max-width: 726px !important;">
    <v-row justify="center" style="text-align: justify; display: flex; " class="topTextManifest mt-15">
      <div class="ma-6 leftText">
        <p class="h1"><b>Introduction</b></p>
        <br>
        <p class="h2">SNAFU is an <b>Italian underground art collective</b> that established a platform
          that leverages the new power NFTs have brought to the art world to <b>provide a steadier revenue stream to the global and local art community.</b>
          The collective created a colorful and engaging platform (also called SNAFU) where artists can mint their work as NFTs and interact directly with patrons
          and collectors. SNAFU also issued a <b>token with the same name</b>, dedicated to creating a <b>DAO</b> that will decide how to sustain the artistic collective’s initiatives,
          financing projects, and young artists. This independent sustainability-oriented thinking positions SNAFU in the first line with other art world pioneers to
          genuinely utilize and maximize the relatively young NFT market to reinvent how we deal and consume art.</p>
      </div>
<!--      <br>-->
<!--      <p class="h2">Under you can read our "Artist Collective's Artbook #1".</p>-->
    </v-row>
    <v-row justify="center" class="mt-15">
      <iframe style="width:700px;height:400px" src="https://online.fliphtml5.com/lceji/nohs/"  seamless="seamless" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen="true" ></iframe>
    </v-row>
    <v-row justify="center" style="text-align: justify; display: flex; " class="topTextManifest mt-15">
      <div class="ma-6 leftText">
        <p class="h1"><b>Manifest</b></p>
        <br>
        <p class="h2"><b>In 1950</b> the mathematician Ben Laposky realized an oscillogram and
          inserted in his processor a mathematical function, obtaining a basis
          for a graphical projection, then with an oscilloscope he varied the
          wavelength of the light rays emitted by the cathode ray tube crea-
          ting distortions. <b>Digital art was born.</b></p>
        <p class="h2"><b>Some time later</b>, in 1985, the father of pop art <b>Andy Warhol</b>, during
          the presentation of the Amiga 1000 Commodore, the first multi-
          media PC, <b>created</b> a work starting from a black and white photo of
          Blondie singer Debby Harry. It is <b>the first recognized work of art
          made with a computer.</b></p>
        <br>
        <p class="h2"><b>Time has passed since then, but digital art has not stopped evolving</b>
          and with the progress of technology, it has expanded its horizons
          and its possibilities. In spite of these advancements, this artistic language has remained linked to the world of advertising and
          graphics for a long time, without receiving the attention it deserves
          and it has been regarded as a minor art, relegated to little more than
          a pastime for its exponents.</p>
        <p class="h2">Yet the possibilities for digital artists are almost endless. Apart from
          2D works with graphic tablets, which are essential today, the means
          and tools now available are so many: photography, 3D graphics, CGI,
          VR, music and more. All this bring creativity and imagination to a
          new level. <b>No longer constrained to the limited space of the canvas
          and sculpture material, the work can literally explode in mammoth
          works of the size of just a few megabytes.</b></p>
        <br>
        <p class="h2">Thank God, the network and the world of Cryptocurrencies have
          come to the rescue of digital artists and in the last year, the attention towards this type of art has risen to new levels. So much so
          that during an auction the work “Everyday: the first 5000 days” by
          the artist Beeple was sold for $ 69 million, a figure never seen before, which testifies to the growth of interest in this language. Now
          everyone is talking about it, and from all over the world thousands of
          artists have poured into the network to showcase their creations.</p>
        <br>
        <p class="h2"><b>A cybernetic golden age has just begun</b>, the doors of mnemonic
          perception have opened wide to all those who feel brave enough to
          cross them... <b>will you be one of them?</b></p>
      </div>

      <div class="ma-6 leftText">
        <p class="h1"><b>The code that becomes art.</b></p>
        <br>
        <p class="h2"><b>SNAFU collective was born</b> from the primordial need of the members <b>to express their emotions through the artistic languages</b>. We
          were islands adrift, but we started to build bridges to communicate
          our passions and shout out to the world that beauty will save it from
          squalor.</p>
        <p class="h2"><b>The beating of our hearts marks a new time, an era that needs new
          spaces and new infinite horizons;</b> we will no longer be relegated to
          the margins of a society that has lost the taste for beauty and won-
          der.</p>
        <br>
        <p class="h2"><b>We want to</b> throw ourselves to the conquest of these new worlds
          and then share them with the whole of humanity, because we are
          inclusive not exclusive.</p>
        <p class="h2"><b>We want to</b> show the wonders of the digital firmament even to the
          most analogical of beings, without forgetting the beauty that nature
          gives us because we can see, we are not blind.</p>
        <br>
        <p class="h2"><b>We want to</b> take back our future, which today seems only a mirage,
          less and less real and more and more nebulous. We are tightro-
          pe walkers suspended on a thread of uncertainties teetering on a
          stormy sea, but we face danger with all our passion because we are
          brave and not cowards.</p>
        <br>
        <p class="h2"><b>We want a brave new world that keeps up with our dreams and
          ambitions because we are the change we would like to see in the
          world.</b></p>
        <p class="h2">We are cyberpunks, graphic designers, painters, programmers,
          photographers, musicians, art lovers trying to give their point
          of view of what is and can be conceived as art in an era where
          everything is digital and immaterial.</p>
        <p class="h2">Since Art is tied to its historical period, like every other artistic movement of the past (Pop art, Abstractism, Performance art, etc.) the
          SNAFU Collective wants to pursue its own goal of creating a new
          form of self-sustenance for young underground artists all around
          the globe. We are engaged in exploring and experimenting this new
          way of conceiving art, testing its limits and finding an answer to the
          question of what can really be considered art in the historical mo-
          ment we live in.</p>
        <br>
        <p class="h2">We navigate in the digital ocean in a stubborn and contrary direction, because <b>we are pirates and we want to take everything we
          deserve.</b></p>
      </div>
    </v-row>
  </v-container>
</template>


<script>
export default {
  components: {
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted() {
  },
}
</script>

<style>
@media screen and (min-width: 768px) {
  .topTextManifest  .h1 {
    font-size: 30px;
  }
  .topTextManifest {
    margin: 40px;
  }
}

@media screen and (max-width: 768px) {
  .topTextManifest {
    margin: 0;
  }
  .topTextManifest .h2 {
    color: rgba(48, 48, 48, 0.91);
    font-family: 'Barlow', sans-serif;
    line-height: 1;
  }
  .h1 {
    font-size: 25px;
  }
}
</style>
